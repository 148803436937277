import styled from 'styled-components';

export const Contact = styled.div`
  color: ${({ theme }) => theme.white};

  font-family: ${({ theme }) => theme.fontBody};
  flex: 1 356px;

  line-height: 26px;
  text-align: center;

  margin-bottom: 1rem;
  width: 100%;

  @media only screen and (min-width: 1024px) {
    line-height: 28px;
  }

  @media only screen and (min-width: 1280px) {
    line-height: 32px;
  }

  @media only screen and (min-width: 768px) {
    width: 32%;
  }
`;

export const Name = styled.div`
  font-family: ${({ theme }) => theme.fontHeader};
  font-size: 1.5rem;
`;

export const Paragraph = styled.div`
  font-family: ${({ theme }) => theme.fontBody};
`;

export const Position = styled.div`
  font-family: ${({ theme }) => theme.fontBody};
`;

export const Email = styled.a`
  color: ${({ theme }) => theme.secondary400};
`;
