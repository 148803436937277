import { Button, Typography } from 'amphitheatre';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { graphql, PageProps } from 'gatsby';
import React from 'react';
import { BsDownload } from 'react-icons/bs';
import Contact from 'src/Components/Contact';
import Container from 'src/Components/ContentContainer';
import SEO from 'src/Components/SEO';
import Layout from 'src/Layout';
import * as Base from 'src/page-styles/base.styled';
import { TemplateBody } from 'src/page-styles/template.styled';
import styled from 'styled-components';

dayjs.extend(advancedFormat);

const { Header } = Typography;

const ContactList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  max-width: 768px;
  margin: auto;
`;

const PressHeading = styled(Base.Heading)`
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: left;

  @media only screen and (min-width: 768px) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

const DisplayImageWrapper = styled.div`
  display: flex;
  margin-bottom: 3rem;
`;

const DisplayImage = styled.img`
  width: 90%;
  margin-inline: auto;
`;

interface Props {
  title: string;
  date: string;
  description: string;
  display_image?: {
    publicURL: string;
  };
  prPDF?: {
    publicURL: string;
  };
  people_contact?: Array<{
    name: string;
    position: string;
    content?: Array<string>;
    email: string;
  }>;
  boilerplate?: string;
  html: string;
  site: {
    siteMetadata: {
      siteUrl?: string;
    };
  };
}

export const PRTemplate: React.FunctionComponent<Props> = ({
  title,
  description,
  date,
  display_image,
  prPDF,
  people_contact,
  boilerplate,
  html,
  site,
}: Props) => {
  return (
    <>
      <SEO
        title={title}
        description={description}
        url={site.siteMetadata?.siteUrl}
        image_url={`${site.siteMetadata?.siteUrl}${display_image?.publicURL}`}
      />
      <Layout>
        <Container.Dark data-cy="press-release-container">
          <Base.MenuGap />
          <Base.Text>
            <PressHeading>{title}</PressHeading>
            <TemplateBody>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {prPDF ? (
                  <a
                    href={prPDF?.publicURL}
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ marginRight: '0.5rem', textDecoration: 'none' }}
                  >
                    <Button
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      Download PDF{' '}
                      <BsDownload style={{ marginLeft: '0.5rem' }} />
                    </Button>
                  </a>
                ) : undefined}
                {dayjs(date).format('Do MMMM YYYY')}
              </div>
            </TemplateBody>
            <hr />
            <TemplateBody
              dangerouslySetInnerHTML={{ __html: html }}
              style={{ textAlign: 'justify' }}
            />
            <br />

            {/* If a display image is included but there are no images in the 
            html body, then add the image to the bottom */}
            {display_image && !html.includes('gatsby-resp-image-wrapper') ? (
              <DisplayImageWrapper>
                <DisplayImage src={display_image.publicURL} />
              </DisplayImageWrapper>
            ) : undefined}
            <hr />
            <br />
            {people_contact ? (
              <Header level={3}>For any enquiries, please contact:</Header>
            ) : undefined}
            <ContactList>
              {people_contact
                ? people_contact.map((contact, index) => (
                    <Contact key={`contact-${index}`} {...contact} />
                  ))
                : undefined}
            </ContactList>
            {people_contact ? <hr /> : undefined}
            <TemplateBody style={{ color: '#a3a3a3', textAlign: 'justify' }}>
              {boilerplate ||
                `Saber Astronautics’ mission is the democratization of space,
                reducing barriers to space flight, and making space as easy as
                driving a car. Saber uses next-generation space mission control
                software developed by an experienced team of space operations,
                systems control, UX, and robotics experts. Saber brings together
                the latest techniques in human factors, artificial intelligence,
                and dynamic 3D data visualization to make it easy for spacecraft
                operators to monitor, fly, and rapidly diagnose faults in
                spacecraft systems.`}
            </TemplateBody>
          </Base.Text>
        </Container.Dark>
      </Layout>
    </>
  );
};

interface PRData {
  markdownRemark: {
    frontmatter: {
      title: string;
      date: string;
      description: string;
      display_image?: {
        publicURL: string;
      };
      pr_pdf?: {
        publicURL: string;
      };
      people_contact?: Array<{
        name: string;
        position: string;
        content?: Array<string>;
        email: string;
      }>;
      boilerplate?: string;
    };
    html: string;
  };
  site: {
    siteMetadata: {
      siteUrl: string;
    };
  };
}

export default function Template({ data }: PageProps): JSX.Element {
  const { markdownRemark, site } = data as PRData;
  const { frontmatter, html } = markdownRemark;

  return (
    <PRTemplate
      {...frontmatter}
      prPDF={frontmatter.pr_pdf}
      html={html}
      site={site}
    />
  );
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        date
        description
        display_image {
          publicURL
        }
        pr_pdf {
          publicURL
        }
        people_contact {
          name
          position
          email
          content
        }
        boilerplate
      }
      html
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
