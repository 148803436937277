import React from 'react';

import { Contact, Email, Name, Paragraph, Position } from './Styled';

interface Props {
  name: string;
  position: string;
  content?: string[] | string;
  email: string;
}

export default function ({
  name,
  position,
  content,
  email,
}: Props): JSX.Element {
  let contentElement = null;

  if (Array.isArray(content)) {
    contentElement = content.map((elm, idx) => (
      <Paragraph key={`${idx}-${elm}`}>{elm}</Paragraph>
    ));
  } else {
    contentElement = <Paragraph>{content}</Paragraph>;
  }

  return (
    <Contact>
      <Name>{name}</Name>
      <Position>{position}</Position>
      {contentElement}
      <Email href={`mailto:${email}`}>{email}</Email>
    </Contact>
  );
}
