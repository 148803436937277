import styled from 'styled-components';

export const TemplateBody = styled.div`
  color: ${({ theme }) => theme.grey300};
  font-family: ${({ theme }) => theme.fontBody};

  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.5px;

  margin-bottom: 1.25rem;

  @media only screen and (min-width: 1024px) {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  margin: 1rem auto 1.5rem;
  max-width: 768px;

  a {
    color: ${({ theme }) => theme.secondary500};
    display: inline-flex;
    align-items: center;

    font-weight: normal;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${({ theme }) => theme.fontHeader};
  }

  img {
    width: 100%;
  }
`;
